
import { datadogRum } from '@datadog/browser-rum';
export const initDataLog = (configs) => {
    if(window.hkConfigEnvs.VUE_DATA_DOG_ENABLE){
        datadogRum.init({
            applicationId: window.hkConfigEnvs.VUE_DATA_DOG_APP_ID,
            clientToken: window.hkConfigEnvs.VUE_DATA_DOG_CLIENT_TOKEN,
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site: window.hkConfigEnvs.VUE_DATA_DOG_SITE,
            service: window.hkConfigEnvs.VUE_DATA_DOG_SERVICE,
            env: window.hkConfigEnvs.VUE_DATA_DOG_ENV,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sessionSampleRate: 100,
            sessionReplaySampleRate: window.hkConfigEnvs.VUE_DATA_DOG_SESSION_REPLAY_SAMPLE_RATE || 20,
            trackUserInteractions: window.hkConfigEnvs.VUE_DATA_DOG_TRACK_USER_INTERACTIONS,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        });
        datadogRum.setUser({
            id: configs.user?.id,
            userName: configs.username,
            name: `${configs?.user?.first_name}  ${configs?.user?.last_name}`,
            propertyCode: configs?.property?.code
            // email: 'email',
            // plan: 'premium'
        })
        // Start collecting RUM events
        datadogRum.startSessionReplayRecording();
    }
}